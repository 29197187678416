import { Component, Input, Output, EventEmitter } from "@angular/core";

/**
 * Component to generate a num items selector
 * 
 * @author Floyd Kissoon
 * @since 20170817
 */
@Component({
    selector: "num-items-selector",
    templateUrl: "./num-items-selector.html"
})
export class NumItemsSelector
{
    @Input() numItems: number;
    @Output() numItemsChange: EventEmitter<number>;

    constructor() {
        this.numItemsChange = new EventEmitter<number>();
    }

    /**
     * Called when an item has been selected
     */
    numItemsChanged() {
        this.numItemsChange.emit(this.numItems);
    }


}
