import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './Config.service';
import { AuthService } from './Auth.service';
import 'rxjs/add/operator/map';

/**
 * Service that provides a wrapper for retrieving data from the server
 * 
 * @author Joshua Kissoon
 * @since 20170709
 */
@Injectable()
export class DataManager {

    public constructor(protected config: ConfigService, protected http: HttpClient,
        protected authService: AuthService) { }

    getAccessToken() {
        return this.authService.getAccessToken();
    }

    getBaseUrl() {
        return this.config.getApiUrl();
    }

    getDefaultHeaders() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAccessToken(),
        });

        return headers;
    }

    /**
     * Callback wrapper for the HTTP GET Call
     * 
     * @param urlq The query part of the URL to be sent to the server
     * 
     * @author Joshua Kissoon
     * @since 20160826
     * @updated 20170711 to add parameters
     */
    GET(urlq: string, params = {}) {
        return this.http
            .get(this.getBaseUrl() + urlq, { headers: this.getDefaultHeaders(), params: params })
            .toPromise();
    }

    /**
     * Callback wrapper for the HTTP POST Call
     * 
     * @param urlq The query part of the URL to be sent to the server
     * @param object The object containing the POST data to be sent
     * 
     * @author Joshua Kissoon
     * @since 20160826
     */
    POST(urlq: string, object: any) {
        if (null == object) {
            object = { "blank": "blank" };
        }

        return this.http
            .post(this.getBaseUrl() + urlq, JSON.stringify(object), { headers: this.getDefaultHeaders() })
            .toPromise();
    }

    /**
     * Callback wrapper for the HTTP POST Call
     * 
     * @param urlq The query part of the URL to be sent to the server
     * @param object The object containing the POST data to be sent
     * 
     * @author Joshua Kissoon
     * @since 20160826
     */
    POSTRAW(urlq: string, object: any) {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.getAccessToken(),
        });

        return this.http
            .post(this.getBaseUrl() + urlq, object, { headers: headers })
            .toPromise();
    }


    /**
     * Callback wrapper for the HTTP PUT Call
     * 
     * @param urlq The query part of the URL to be sent to the server
     * @param object The object containing the PUT data to be sent
     * 
     * @author Joshua Kissoon
     * @since 20160826
     */
    PUT(urlq: string, object: any) {
        if (null == object) {
            object = { "blank": "blank" };
        }

        return this.http
            .put(this.getBaseUrl() + urlq, JSON.stringify(object), { headers: this.getDefaultHeaders() })
            .toPromise();
    }

    /**
     * Callback wrapper for the HTTP PATCH Call
     * 
     * @param urlq The query part of the URL to be sent to the server
     * @param object The object containing the PUT data to be sent
     * 
     * @author Joshua Kissoon
     * @since 20161107
     */
    PATCH(urlq: string, object: any) {
        if (null == object) {
            object = { "blank": "blank" };
        }

        return this.http
            .patch(this.getBaseUrl() + urlq, JSON.stringify(object), { headers: this.getDefaultHeaders() })
            .toPromise();
    }

    /**
     * Callback wrapper for the HTTP DELETE Call
     * 
     * @param urlq The query part of the URL to be sent to the server
     * 
     * @author Joshua Kissoon
     * @since 20160826
     */
    DELETE(urlq: string) {
        return this.http
            .delete(this.getBaseUrl() + urlq, { headers: this.getDefaultHeaders() })
            .toPromise();
    }

}