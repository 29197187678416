import { Injectable } from "@angular/core";

import { AlertService } from "./Alert.service";

/**
 * Service used to handle errors from the request
 * 
 * @author Joshua Kissoon
 * @since 20171202
 */
@Injectable()
export class RequestErrorService {

    constructor(private alerter: AlertService) {
    }

    handleError(error: any) {
        if (error.status == 422) {
            var data = JSON.parse(error.text());

            for (var field in data) {
                if (data[field] instanceof Array) {
                    for (var item of data[field]) {
                        this.alerter.addAlert(item, "warning");
                    }
                }
                else {
                    this.alerter.addAlert(data[field], "warning");
                }
            }
        }
        else if (error.status == 401 || error.status == 403) {
            this.alerter.addAlert("Sorry, you're either not logged in or don't have permission to access the resource or perform the action. ", "warning");
        }

    }
}
