import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";

/**
 * Component to generate a number selector
 * 
 * @author Floyd Kissoon
 * @since 20180628
 */
@Component({
    selector: "number-selector",
    templateUrl: "./number-selector.html"
})
export class NumberSelector implements OnInit {
    @Input() start_value: number;
    @Input() end_value: number
    @Output() selectedValueChange: EventEmitter<number>;
    @Input() selectedValue: number;

    items = new Array();
    
    constructor() {
        this.selectedValueChange = new EventEmitter<number>();
    }

    /**
     * Called when an item has been selected
     */
    numChanged() {
        this.selectedValueChange.emit(this.selectedValue);
    }


    ngOnInit() {
        for (var i = this.start_value; i < this.end_value + 1; i++) {
            this.items.push(i);
        }
    }
}
