import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* Selectors */
import { NumItemsSelector } from './num-items-selector.component';
import { DateUtils } from './DateUtils.service';
import { NumberSelector } from './number-selector.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        BrowserAnimationsModule,
    ],
    declarations: [
        NumItemsSelector, NumberSelector,
    ],
    providers: [DateUtils, NumItemsSelector, NumberSelector],
    exports: [NumItemsSelector, NumberSelector]
})
export class UtilityModule { }
