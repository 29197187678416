import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* Core Services */
import { AlertService } from './Alert.service';
import { AuthService } from './Auth.service';
import { AuthGuard } from './AuthGuard.service';
import { ConfigService } from './Config.service';
import { DataManager } from './DataManager.service';
import { RequestErrorService } from './RequestError.service';
import { StorageService } from './storage.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule
    ],
    declarations: [

    ],
    providers: [
        AlertService, 
        AuthService, 
        AuthGuard, 
        ConfigService, 
        DataManager, 
        RequestErrorService, 
        StorageService
    ],
    exports: []
})
export class TechlifyServicesModule { }
